<template>
  <div>
    <section>
      <ul class="mm_menu">
        <li class="w-100" @click="$emit('toggle:menu', !expanded)">
          <component
            :class="{ 'w-100 d-flex justify-content-between': expanded }"
            :is="expanded ? 'div' : 'router-link'"
            to=""
          >
            <img
              class="forward-icon"
              src="/images/fast-forward.png"
              width="20"
              alt=""
            />
            <img
              :class="{ 'd-none': !expanded }"
              :src="getSettings.logo"
              width="100"
              alt=""
            />
          </component>
        </li>
      </ul>
    </section>
    <section>
      <div class="storage mb-4">
        <h6 class="mb-2">{{ $t("storage") }}</h6>
        <div
          v-if="!getLoadingOfFileSize"
          class="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :class="RETURN_CLASS()"
            :style="`width: ${FIND_PERCENT(getFileSize.usedSize)}%`"
          ></div>
        </div>
        <div v-if="getFileSize.usedSize">
          <small
            class="text-muted"
            :title="getFileSize.usedSize"
            v-html="
              $t('fileUsage', {
                usedSize: CONVERT_TO_GB(getFileSize.usedSize),
                percent: FIND_PERCENT(getFileSize.usedSize),
                total: storageModule.value,
              })
            "
          >
          </small>
        </div>
        <div v-else>
          <small
            ><i> {{ $t("loading") }}</i></small
          >
        </div>
      </div>
    </section>
    <!-- end-->
    <section>
      <ul class="mm_menu">
        <li id="v-step-0" class="w-100" @click="$emit('toggle:menu', false)">
          <router-link
            to="/"
            exact-active-class="exact-active"
            :title="$t('home')"
          >
            <i class="icon-home"></i>
            <span>
              {{ $t("home") }}
            </span>
          </router-link>
        </li>
        <li id="v-step-1" class="w-100" @click="$emit('toggle:menu', false)">
          <router-link
            to="/calendar"
            exact-active-class="exact-active"
            :title="$t('calendar')"
          >
            <i class="icon-calender"></i>
            <span>
              {{ $t("calendar") }}
            </span>
            <span v-if="!calendarModule">
              <i class="icon-features"></i>
            </span>
          </router-link>
        </li>
        <li id="v-step-2" class="w-100" @click="$emit('toggle:menu', false)">
          <a
            v-if="siteBuilderModule"
            target="_blank"
            :href="'https://' + getSettings.website + '/heo-admin'"
            :title="$t('siteBuilder')"
          >
            <i class="icon-pencil"></i>
            <span>
              {{ $t("siteBuilder") }}
            </span>
          </a>
          <a v-else class="text-primary">
            <i class="icon-features"></i>
            <span>
              {{ $t("siteBuilder") }}
            </span>
          </a>
        </li>
      </ul>
    </section>
    <section>
      <h6>{{ $t("products") }}</h6>
      <ul class="mm_menu">
        <li
          :id="`v-step-p-${link.storePath}`"
          class="w-100"
          @click="$emit('toggle:menu', false)"
          v-for="(link, i) in Types"
          :key="i"
        >
          <router-link
            :to="link.domain"
            exact-active-class="exact-active"
            :title="link.title"
          >
            <img :src="link.icon" width="20" :alt="link.title" />
            <span>
              {{ link.title }}
            </span>
          </router-link>
        </li>
      </ul>
    </section>
    <hr />
    <section>
      <h6 :class="{ 'line-1 mb-2': expanded }">{{ getSettings.title }}</h6>
      <ul class="mm_menu">
        <li
          class="w-100"
          :id="`v-step-l-${link.tour}`"
          @click="$emit('toggle:menu', false)"
          v-for="(link, i) in links"
          :key="i"
        >
          <router-link
            :to="link.to"
            exact-active-class="exact-active"
            :title="link.title"
          >
            <i :class="`icon-${link.icon}`"></i>
            <span> {{ link.title }}</span>
          </router-link>
        </li>
      </ul>
    </section>

    <hr />
    <section>
      <h6 class="mb-2">{{ $t("reportCenter") }}</h6>
      <ul class="mm_menu">
        <li
          class="w-100"
          @click="$emit('toggle:menu', false)"
          v-for="(link, i) in reportCenter"
          :key="i"
        >
          <router-link
            :to="link.to"
            exact-active-class="exact-active"
            :title="link.title"
          >
            <template v-if="!link.image">
              <i :class="`icofont-${link.icon}`"></i>
              <span>
                {{ link.title }}
              </span>
            </template>
            <template v-else>
              <img :src="`/images/${link.icon}`" width="20" alt="" />
              <span>
                {{ link.title }}
              </span>
            </template>
          </router-link>
        </li>
      </ul>
    </section>
    <hr />
    <section>
      <h6 class="mb-2">{{ $t("myProfile") }}</h6>
      <ul class="mm_menu">
        <li
          class="w-100"
          @click="$emit('toggle:menu', false)"
          v-for="(link, i) in accountLinks"
          :key="i"
        >
          <router-link
            :to="link.to"
            exact-active-class="exact-active"
            :title="link.title"
          >
            <template v-if="!link.image">
              <i :class="`icon-${link.icon}`"></i>
              <span>
                {{ link.title }}
              </span>
            </template>
            <template v-else>
              <img :src="`/images/${link.icon}`" width="20" alt="" />
              <span>
                {{ link.title }}
              </span>
            </template>
          </router-link>
        </li>
      </ul>
    </section>
    <!-- end-->
    <section>
      <ul class="mm_menu">
        <li
          class="w-100"
          @click="
            $store.commit('SET_LOGOUT', {
              variant: 'success',
              message: 'sessionDestroyed',
            })
          "
        >
          <a href="javascript:void(0)" title="">
            <i class="icon-logout"></i>
            <span>
              {{ $t("logout") }}
            </span>
          </a>
        </li>
      </ul>
    </section>
    <!-- end-->
    <v-tour
      name="myTour"
      :steps="
        steps.map((step) => ({
          target: step.target,
          header: {
            title: $t(step.header.title),
          },
          content: $t(step.content),
        }))
      "
    ></v-tour>
  </div>
  <!--side_menu end-->
</template>

<script>
import Types from "../../Type/Types";
import StorageOfApp from "../../../mixins/StorageOfApp";
import Modules from "../../../mixins/Modules";
export default {
  mixins: [Types, StorageOfApp, Modules],
  props: {
    expanded: {
      default: false,
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getRezConnection() {
      return this.$store.state.Rezervation.connected;
    },
  },
  data() {
    return {
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "step1.title",
          },
          content: "step1.description",
        },
        {
          target: "#v-step-1", // We're using document.querySelector() under the hood
          header: {
            title: "step2.title",
          },
          content: "step2.description",
        },
        {
          target: "#v-step-p-Events", // We're using document.querySelector() under the hood
          header: {
            title: "step3.title",
          },
          content: "step3.description",
        },
        {
          target: "#v-step-p-Courses", // We're using document.querySelector() under the hood
          header: {
            title: "step4.title",
          },
          content: "step4.description",
        },
        {
          target: "#v-step-p-Books", // We're using document.querySelector() under the hood
          header: {
            title: "step5.title",
          },
          content: `step5.description`,
        },
        {
          target: "#v-step-p-Blogs", // We're using document.querySelector() under the hood
          header: {
            title: "step6.title",
          },
          content: "step6.description",
        },
        // {
        //   target: "#v-step-p-Seances", // We're using document.querySelector() under the hood
        //   header: {
        //     title: "Seanslarım / Randevular",
        //   },
        //   content: `Seanslarınızı / Randevularınızı buradan yönetebilirsiniz.`,
        // },
        {
          target: "#v-step-l-0", // We're using document.querySelector() under the hood
          header: {
            title: "step7.title",
          },
          content: "step7.description",
        },
        {
          target: "#v-step-l-1", // We're using document.querySelector() under the hood
          header: {
            title: "step8.title",
          },
          content: "step8.description",
        },
        {
          target: "#v-step-l-2", // We're using document.querySelector() under the hood
          header: {
            title: "step9.title",
          },
          content: "step9.description",
        },
        {
          target: "#v-step-l-3", // We're using document.querySelector() under the hood
          header: {
            title: "step10.title",
          },
          content: "step10.description",
        },
        {
          target: "#v-step-l-4", // We're using document.querySelector() under the hood
          header: {
            title: "step11.title",
          },
          content: "step11.description",
        },
        {
          target: "#v-step-l-5", // We're using document.querySelector() under the hood
          header: {
            title: "step12.title",
          },
          content: `step12.description`,
        },
        {
          target: "#v-step-l-6", // We're using document.querySelector() under the hood
          header: {
            title: "step13.title",
          },
          content: "step13.description",
        },
      ],
      links: [
        {
          to: "/categories",
          title: this.$t("categories"),
          icon: "grid",
          tour: 0,
        },
        { to: "/user-list", title: this.$t("users"), icon: "user", tour: 1 },
        {
          to: "/instructor-list",
          title: this.$t("instructors"),
          icon: "user",
          tour: 2,
        },
        {
          to: "/comments",
          title: this.$t("comments"),
          icon: "message",
          tour: 3,
        },
        {
          to: "/new-product",
          title: this.$t("uploadProduct"),
          icon: "plus",
          tour: 4,
        },
        {
          to: "/wp-connection",
          title: this.$t("wp-connection"),
          icon: "preferences",
          tour: 5,
        },
        {
          to: "/payment-methods",
          title: this.$t("paymentMethods"),
          icon: "paid_sub",
          tour: 6,
        },
        {
          to: "/quizzes",
          title: this.$t("quizzes"),
          icon: "grid",
          tour: 7,
        },
      ],
      reportCenter: [
        {
          to: "/course-insights",
          title: this.$t("courseInsights"),
          icon: "chart-line",
        },
        {
          to: "/product-revenues",
          title: this.$t("productRevenues"),
          icon: "coins",
        },
        {
          to: "/instructor-revenues",
          title: this.$t("instructorRevenues"),
          icon: "coins",
        },
        { to: "/orders", title: this.$t("orders"), icon: "handshake-deal" },
        {
          to: "/canceled-orders",
          title: this.$t("canceledSales"),
          icon: "undo",
        },
        {
          to: "/certificate",
          title: this.$t("certification"),
          icon: "certificate",
        },
        { to: "/grade-book", title: this.$t("gradeBook"), icon: "medal" },

        {
          to: "/quiz-statistics",
          title: this.$t("quizStatistics"),
          icon: "certificate",
        },
      ],
      accountLinks: [
        {
          to: "/my-subscription",
          title: this.$t("mySubscription"),
          icon: "subscriptions",
          image: false,
          rezConnection: false,
        },
        {
          to: "/profile?push=update-image",
          title: this.$t("profileImageTitle"),
          icon: "user",
          image: false,
          rezConnection: false,
        },
        {
          to: "/profile?push=update-information",
          title: this.$t("profileUpdateTitle"),
          icon: "user-edit.png",
          image: true,
          rezConnection: false,
        },
        {
          to: "/profile?push=update-password",
          title: this.$t("changePasswordTitle"),
          icon: "padlock.png",
          image: true,
          rezConnection: false,
        },
        {
          to: "/subscription-policy",
          title: this.$t("subscriptionPolicy"),
          icon: "donations",
          image: false,
          rezConnection: false,
        },
        {
          to: "/profile?push=rezervation",
          title: this.$t("rezInfoTitle"),
          icon: "event.png",
          image: true,
          rezConnection: true,
        },
      ],
    };
  },
  mounted() {
    console.log(this.getSettings);
    if (this.getSettings.isSeances === 0) this.accountLinks.pop();
    if (this.getSettings.departmentId === 2) this.links.splice(6, 1);
    if (!this.getActiveUser.whatsappIsActive) this.links.splice(4, 1);
    if (this.getActiveUser.roleId !== 1) this.links.splice(1, 2);
  },
};
</script>
